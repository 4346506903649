export const DOSPACE_ENDPOINT = process.env.DOSPACE_ENDPOINT;
export const PRODUCTS_PER_PAGE = 30;
export const MAX_FILE_SIZE = process.env.MAX_FILE_SIZE ?? 5;
export const API_SECRET = process.env.API_SECRET;
export const FLOW_API_KEY = process.env.FLOW_API_KEY;
export const TOS_NOTIFY_EMAILS = process.env.TOS_NOTIFY_EMAILS;
export const PIXABAY_API_KEY = process.env.PIXABAY_API_KEY;

export const DOSPACE_UPLOADFOLDERNAME = process.env.DOSPACE_UPLOADFOLDERNAME;
export const DOSPACE_AUTOFILLLOGOFOLDERNAME =
  process.env.DOSPACE_AUTOFILLLOGOFOLDERNAME;
export const DOSPACE_SAVEDFOLDERNAME = process.env.DOSPACE_SAVEDFOLDERNAME;
export const DOSPACE_USERFILEUPLOADFOLDERNAME =
  process.env.DOSPACE_USERFILEUPLOADFOLDERNAME;
export const DOSPACE_ORDERSFOLDERNAME = process.env.DOSPACE_ORDERSFOLDERNAME;

export const DIRECTUS_ICON_UPLOAD_FOLDER = "51644ccf-a621-4430-ba15-740b4a2702d4";
export const DIRECTUS_PHOTOS_UPLOAD_FOLDER = "27b4d1fc-362c-4fe8-be8a-4d890c596b69";
export const DIRECTUS_CLIPART_UPLOAD_FOLDER = "404be1f9-5679-44f5-92e1-007e8d9329ff";
