// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import LoginModal from '@/components/auth/Login';
import RegisterModal from '@/components/auth/Register';
import Router from 'next/router';
import { useSession } from 'next-auth/react';
import { useSiteInfo } from './SiteInfoContext';
import WelcomeModal from '@/components/modals/WelcomeModal';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { status, data: session } = useSession();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [callback, setCallback] = useState(null);

  const { siteInfo } = useSiteInfo();

  const [showAutofillPopup, setShowAutofillPopup] = useState(false);
  const isReadyToShow = showAutofillPopup && siteInfo && siteInfo.site_name;

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const closeModals = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
    setCallback(null);
  };

  const value = {
    openLoginModal,
    openRegisterModal,
    closeModals,
    setCallback,
    loginModalOpen,
    registerModalOpen,
  };

  const showWelcomeModal = (type) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
    localStorage.setItem('welcome_popup', 'true');
    setShowAutofillPopup(true);
  };

  const handleWelcomeModalClose = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
    setCallback(null);
    setShowAutofillPopup(false);
    localStorage.removeItem('welcome_popup');
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', (url) => {
      closeModals();
    });
  }, []);

  useEffect(() => {
    if (status === 'authenticated' && session && session.user) {
      const test = localStorage.getItem('welcome_popup');
      if (test && test === 'true') {
        showWelcomeModal();
        localStorage.removeItem('welcome_popup');
      } else handleWelcomeModalClose();
    }
  }, [status]);

  return (
    <>
      <AuthContext.Provider value={value}>
        {children}
        <LoginModal
          show={loginModalOpen}
          closeModal={closeModals}
          openRegisterModal={openRegisterModal}
          callback={callback}
          showWelcomeModal={showWelcomeModal}
        />
        <RegisterModal
          show={registerModalOpen}
          closeModal={closeModals}
          openLoginModal={openLoginModal}
          callback={callback}
        />
        {isReadyToShow && (
          <WelcomeModal
            showAutofillPopup={showAutofillPopup}
            setShowAutofillPopup={setShowAutofillPopup}
            siteInfo={siteInfo}
            handleWelcomeModalClose={handleWelcomeModalClose}
          />
        )}
      </AuthContext.Provider>
    </>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
